.navigation {
  display: flex;
  flex-direction: row;
}

.nav-link {
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
}
