.introduction {
  width: 320px;
  height: 80vh;
  padding: 24px;
  border-radius: var(--border-radius);
  display: grid;
  grid-template-rows: 2fr 1fr 1fr 3fr;
  box-sizing: border-box;
  gap: 16px;
  overflow: hidden;
}

.introduction-heading {
  align-self: end;
  font-size: 32px;
}

.introduction-subheading, .introduction-project-link {
  font-size: 24px;
}

.introduction-image {
  align-self: end;
}

.introduction-image {
  position: relative;
  left: -8px;
}

