.project-cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.project-card {
  box-sizing: border-box;
  border-radius: var(--border-radius);
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto;
  grid-template-areas:
    "thumbnail"
    "text";
    padding: 12px;
    align-items: start;
    justify-items: start;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-card-image-thumbnail {
  justify-self: center;
  grid-area: thumbnail;
  object-fit: cover;
  border-radius: var(--border-radius);
  justify-self: center;
}

.project-card-text {
  display: grid;
  grid-template-rows: 2fr 1fr auto;
  grid-template-areas:
  "title"
  "technology"
  "summary";
}

.project-card-title {
  grid-area: title;
  text-transform: uppercase;
  font-size: 18px;
  justify-self: start;
  align-self: flex-end;
  padding-left: 12px;
  padding: 12px 16px;
}

.project-card-technology {
  grid-area: technology;
  font-size: 14px;
  padding-top: 8px;
  padding-left: 12px;
}

.project-card-summary {
  grid-area: summary;
  font-size: 14px;
  padding-top: 8px;
  padding-left: 12px;
}

@media (min-width: 480px) {
  .project-card {
    grid-template-rows: 1fr;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "thumbnail text";
    padding: 12px 24px;
    gap: 16px;
  }

  .project-card-image-thumbnail {
    justify-self: start;
  }
} 