.project-page-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "title"
    "heading"
    "video"
    "summary"
    "external-links"
    "prev-next-links";
}

.project-title {
  grid-area: title;
  font-size: 12px;
  padding: 24px 24px 12px;
}

.project-heading {
  grid-area: heading;
  font-size: 36px;
  font-weight: 600;
  padding: 24px 24px 42px;
  line-height: 1.25;
}

.project-video {
  grid-area: video;
  width: 100%;
  box-sizing: border-box;
}

.project-summary {
  grid-area: summary;
  padding: 48px 24px 64px;
}

.project-external-links {
  grid-area: external-links;
  font-size: 12px;
  padding: 4px 24px;
  text-transform: uppercase;
}

.project-summary h2 {
  font-size: 12px;
  text-transform: uppercase;
  padding: 8px 0px;
  text-align: center;
}

.project-summary p {
  padding-top: 12px;
}

.prev-next-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "prev next";
  font-size: 12px;
  padding: 12px 24px;
  height: 16px;
}

.prev {
  grid-area: prev;
  justify-self: start;
}

.next {
  grid-area: next;
  justify-self: end;
}

.prev::before {
  content: "< ";
}

.next::after {
  content: " >";
}
